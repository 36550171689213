import React, { Fragment, useEffect, useContext } from "react"
import { Link as RouterLink } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import axios from "axios"

import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  Paper,
  useMediaQuery,
  Button,
  Link as StyleLink,
  Divider,
} from "@material-ui/core"

import SEO from "../components/seo"
import Card from "../components/card"

import sortByKeyValue from "../functions/sortByKeyValue"

import { appContext } from "../context/provider"

const Videos = () => {
  const { videoImage, channelImage } = useStaticQuery(
    graphql`
      query {
        videoImage: file(relativePath: { eq: "randopourlesnuls-video.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              src
            }
          }
        }
        channelImage: file(
          relativePath: { eq: "randopourlesnuls-chaine.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 50) {
              src
            }
          }
        }
      }
    `
  )

  const isXs = useMediaQuery(theme => theme.breakpoints.only("xs"))
  const isSm = useMediaQuery(theme => theme.breakpoints.only("sm"))
  const isMd = useMediaQuery(theme => theme.breakpoints.only("md"))
  const isLg = useMediaQuery(theme => theme.breakpoints.only("lg"))
  const isXl = useMediaQuery(theme => theme.breakpoints.only("xl"))

  const { appData, setAppData } = useContext(appContext)

  useEffect(() => {
    console.log("=== Videos ===")
  })

  useEffect(() => {
    if (!appData.videos && appData.ids) {
      console.log("[ LOAD ] ==> appData.videos")

      const videosIds = appData.ids.videos
      let youtubeData = []
      let youtubeVideos = []
      let youtubeChannels = []

      videosIds.length > 0 &&
        axios
          .get(
            `https://www.googleapis.com/youtube/v3/videos?key=${
              process.env.GATSBY_GOOGLE_API_KEY
            }&id=${videosIds.join(
              ","
            )}&part=snippet&fields=items(id,snippet(title,thumbnails,channelId))`
          )
          .then(result => {
            const videos = result.data.items
            // console.log("videos", videos.length)

            let channelsIds = []

            videos.forEach(item => {
              const {
                id,
                snippet: {
                  title,
                  thumbnails: { maxres, standard, high },
                  channelId,
                },
              } = item

              let videoData = {
                id: id,
                index: videosIds.findIndex(index => index === id),
                title: title,
                url: `https://www.youtube.com/watch?v=${id}`,
                image: maxres ? maxres.url : standard ? standard.url : high.url,
                channel: channelId,
              }

              youtubeVideos = [...youtubeVideos, videoData]
              // if (youtubeVideos.length === videos.length) {
              // console.log(youtubeVideos)
              // }

              channelsIds = [...channelsIds, channelId]
              if (channelsIds.length === videos.length) {
                // console.log("channelsIds", channelsIds.length)

                axios
                  .get(
                    `https://www.googleapis.com/youtube/v3/channels?key=${
                      process.env.GATSBY_GOOGLE_API_KEY
                    }&id=${channelsIds.join(
                      ","
                    )}&part=snippet&fields=items(id,snippet(title, thumbnails))`
                  )
                  .then(result => {
                    const channels = result.data.items
                    // console.log("channels", channels.length)

                    channels.forEach(item => {
                      const {
                        id,
                        snippet: { title, thumbnails },
                      } = item

                      let channelData = {
                        id: id,
                        title: title,
                        url: `https://www.youtube.com/channel/${id}`,
                        image: thumbnails.default.url,
                      }

                      youtubeChannels = [...youtubeChannels, channelData]
                      if (youtubeChannels.length === videos.length) {
                        // console.log(youtubeChannels)

                        youtubeVideos.forEach(video => {
                          youtubeData = [
                            ...youtubeData,
                            {
                              ...video,
                              channel: youtubeChannels.find(
                                channel => channel.id === video.channel
                              ),
                            },
                          ]

                          if (youtubeData.length === videos.length) {
                            // console.log(youtubeData)
                            const nextYoutubeData = sortByKeyValue(
                              youtubeData,
                              "DESC",
                              "index"
                            )
                            const nextAppData = {
                              ...appData,
                              videos: nextYoutubeData,
                            }
                            setAppData(nextAppData)
                            // console.log("appData", nextAppData)
                          }
                        })
                      }
                    })
                  })
              }
            })
          })
    }
  }, [appData, setAppData])

  return (
    <Fragment>
      <SEO title="Les vidéos" />

      <Typography paragraph variant="h4">
        Les vidéos {appData.videos && `(${appData.videos.length})`}
      </Typography>

      <Typography>
        RandoPassion est une collaboration de vidéastes francophones (France,
        Suisse, Belgique, Québec) passionnés de randonnée. RandoPassion
        rassemble des témoignages de randonneuses et de randonneurs aux profils,
        pratiques et expériences variés.{" "}
        <StyleLink component={RouterLink} to="/about">
          Lire la suite...
        </StyleLink>
      </Typography>

      <Box marginY={4}>
        <Divider />
      </Box>

      <Grid
        container
        justify={appData.videos ? "flex-start" : "center"}
        spacing={isXs ? 2 : isSm ? 4 : isMd ? 6 : isLg ? 8 : isXl ? 10 : null}
      >
        {/* {Array.from(new Array(12)).map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Box margin={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
              <Card
                videoTitle="Titre de la vidéo #RandoPassion"
                videoImage={`https://source.unsplash.com/800x450/?mountain&sig=${index}`}
                videoUrl={`https://source.unsplash.com/800x450/?mountain&sig=${index}`}
                channelTitle="Nom de la chaine"
                channelImage={`https://source.unsplash.com/200x200/?face&sig=${index}`}
                channelUrl={`https://source.unsplash.com/200x200/?face&sig=${index}`}
              />
            </Box>
          </Grid>
        ))} */}

        {appData.videos ? (
          <Fragment>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                videoTitle={"La randonnée, bien plus qu'une activité !"}
                videoImage={videoImage.childImageSharp.fluid.src}
                videoUrl={
                  "https://www.facebook.com/LaRandoPourLesNuls/posts/2356512231323407"
                }
                channelTitle={"La Rando Pour les Nuls"}
                channelImage={channelImage.childImageSharp.fluid.src}
                channelUrl={"https://www.facebook.com/LaRandoPourLesNuls"}
              />
            </Grid>
            {appData.videos.map(video => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={video.index}>
                {video.channel && (
                  <Card
                    videoTitle={video.title}
                    videoImage={video.image}
                    videoUrl={video.url}
                    channelTitle={video.channel.title}
                    channelImage={video.channel.image}
                    channelUrl={video.channel.url}
                  />
                )}
              </Grid>
            ))}
          </Fragment>
        ) : (
          <Box marginY={8} align="center">
            <Paper elevation={3}>
              <Box padding={4}>
                {true ? (
                  <Fragment>
                    <CircularProgress />
                    <Typography>Chargement des vidéos...</Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography paragraph>
                      Patience, les vidéos arrivent dans quelques jours ;-)
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      to="/channels"
                    >
                      Voir la liste des participants
                    </Button>
                  </Fragment>
                )}
              </Box>
            </Paper>
          </Box>
        )}
      </Grid>
    </Fragment>
  )
}

export default Videos
