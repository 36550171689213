import React from "react"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grid,
  Box,
  Fab,
  Link as StyleLink,
  CircularProgress,
} from "@material-ui/core"

import {
  PlayArrow as PlayIcon,
  AccountCircle as AccountIcon,
} from "@material-ui/icons"

import Image from "material-ui-image"

const useStyles = makeStyles({
  button: {
    zIndex: 1,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  image: {
    transition: "transform .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
})

const VideoCard = props => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Box position="relative">
      <Box position="absolute" top={0} right={0} bottom={0} left={0}>
        <Box position="relative" paddingTop="calc(9 / 16 * 100%)">
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <StyleLink
              href={props.videoUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Fab size="large" className={classes.button}>
                <PlayIcon fontSize="large" />
              </Fab>
            </StyleLink>
          </Box>
        </Box>
      </Box>

      <Card>
        <CardActionArea>
          <Box
            position="relative"
            paddingTop="calc(9 / 16 * 100%)"
            overflow="hidden"
          >
            <StyleLink
              href={props.videoUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                className={classes.image}
              >
                <Image
                  src={props.videoImage}
                  aspectRatio={16 / 9}
                  disableError
                  style={{ backgroundColor: theme.palette.background.paper }}
                  alt={props.videoTitle}
                  title={props.videoTitle}
                />
              </Box>
            </StyleLink>
          </Box>

          <CardContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <StyleLink
                  href={props.channelUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={props.channelImage}
                    aspectRatio={1 / 1}
                    errorIcon={
                      <AccountIcon
                        style={{
                          width: 48,
                          height: 48,
                          color: theme.palette.text.icon,
                        }}
                      />
                    }
                    loading={<CircularProgress size={48 / 2} />}
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      width: "48px",
                      height: "48px",
                    }}
                    imageStyle={{
                      borderRadius: "50%",
                    }}
                    alt={props.channelTitle}
                    title={props.channelTitle}
                  />
                </StyleLink>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography gutterBottom noWrap>
                  <StyleLink
                    color="inherit"
                    href={props.videoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.videoTitle}
                  </StyleLink>
                </Typography>

                <Typography variant="body2" color="textSecondary" noWrap>
                  <StyleLink
                    color="inherit"
                    href={props.channelUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.channelTitle}
                  </StyleLink>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  )
}

export default VideoCard
